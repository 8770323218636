import React from 'react'
import {Link} from 'gatsby'
import Layout from "../../components/full-layout/full-layout"
import style from "../messaggio.module.css"
import pallavicini from "../../img/Pallavicini.jpg"


export default () => {
    return (
        <Layout>
            <main className={style.main}>
                <header>
                    <p> <Link to="/ramadan-2017"><b>&lsaquo; Torna agli Eventi del 2017</b></Link> </p>
                    <h1 className="bordo">MESSAGGIO PER IL <span className="rosso">RAMADAN 2017</span></h1>
                </header>
                <p className="size-3">Troppo spesso ci viene chiesto cosa fa il mondo islamico per l'integrazione dei poveri immigrati in Italia e crediamo che la risposta possa passare anche da questi primi piccoli gesti concreti.</p>
                <p>Da alcuni anni la COREIS (Comunità Religiosa Islamica) ITALIANA ha avviato un percorso di assistenza e sostegno agli immigrati musulmani per favorire non solo l'insegnamento volontario della lingua italiana, l'accompagnamento alla cittadinanza italiana e allo studio e al lavoro in Italia, ma anche la sensibilizzazione al patrimonio spirituale e culturale dell'Islam nel dialogo interreligioso e interculturale.</p>
                <p>Abbiamo visto che il mese di Ramadan rappresenta il momento più benedetto per avvicinare nuovamente questi nostri fratelli e sorelle alla loro e nostra comune origine e identità religiosa, organizzando momenti di rottura del digiuno, preghiere serali e semplici conversazioni di fratellanza e conforto morale con alcuni giovani musulmani italiani.</p>
                <p>Molti di questi immigrati sono di fede o cultura islamica anche se le drammatiche condizioni di povertà, guerra o ingiustizia sociale non hanno permesso a queste persone di ricevere una educazione sui valori autentici della religione e della civiltà islamica. Integrazione nel contesto occidentale significa per la COREIS anche la consapevolezza della dignità, della intelligenza e della libertà della propria identità musulmana, senza formalismi, senza confusioni politiche o ideologiche e senza ghetti.</p>
                <p>Il titolo della nostra iniziativa è 114 Pizza e Dolci e prevede l'offerta di una cena organizzata in collaborazione con associazioni cristiane e laiche locali che si occupano da anni di assistenza e sostegno agli immigrati dal Mediterraneo, dal Medio Oriente, dall'Africa e dall'Asia. </p>
                <p>Il titolo e il numero 114 Pizza e Dolci vuole fare riferimento ai 114 capitoli del Sacro Corano che durante il mese di Ramadan vengono recitati e ricordati come ispirazione e benedizione per la comunità islamica da 14 secoli. </p>
                <p>La pizza è un alimento semplice e anche un elemento simbolico di integrazione nella società e cultura italiana. I dolci sono una naturale conclusione al gusto spirituale e al conforto che la presenza del Profeta Muhammad trasmetteva ai bambini e agli adulti.</p>
                <p>Ci tengo a chiarire che le 114 Pizza e Dolci non sono rivolti solo ai migranti o ai rifugiati di origine musulmana ma a coloro che vorranno condividere un pasto offerto dalla COREIS per onorare tutti i migranti e i rifugiati e ricordare insieme, per una sera simbolica e fraterna, anche questo momento benedetto del nostro calendario e della nostra religione. Non vogliamo quindi escludere nessuno o "islamizzare" la cena ma offrire una occasione di convivialità fraterna con una preghiera, un riferimento, un pensiero, anche al Ramadan e al Dio Misericordioso di tutti. </p>
                <p>La COREIS è convinta che questa iniziativa, molto semplice e distribuita per tappe sul territorio nazionale, possa rappresentare un segnale molto positivo di condivisione, convivialità e conoscenza della ospitalità musulmana sia per i musulmani ma anche per le associazioni di volontari cristiani e laici e per le amministrazioni locali che insieme a noi collaborano a questo evento.</p>
                <p>Speriamo infatti di ispirare un modello di best practise per l'Integrazione e il rispetto della cultura islamica e italiana. </p>
                <img className={style.profilePic} src={pallavicini} alt="Imam Yahya Pallavicini"/>
                <p className="bordo"><b>Imam Yahya Pallavicini</b> <br/> Presidente COREIS (Comunità Religiosa Islamica) Italiana</p>
            </main>
        </Layout>
    )
}