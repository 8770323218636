import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/basic-layout/basic-layout"
import style from "./index.module.css"
import logo from "../img/logo.png"
import Card from "../components/card/card"
import slugify from "../utils/slugify"

import aice from "../img/sponsor2019/aice.png"
import coopfond from "../img/sponsor2019/coopfond.jpg"
import cariplo from "../img/sponsor2019/cariplo.png"
import sanpaolo from "../img/sponsor2019/sanpaolo.png"
import iom from "../img/sponsor2019/iom.jpg"
import halalitalia from "../img/sponsor2019/halalitalia.png"
import iu from "../img/sponsor2019/iu.png"
import kuwait from "../img/sponsor2019/kuwait.png"
import my from "../img/sponsor2019/my.png"
import saudiarabia from "../img/sponsor2019/saudiarabia.png"

export default class IndexPage extends React.Component {
  
  state = {
    filter: "all"
  }


  handleClick = (citta) => {
    this.setState({
      filter: citta
    })
  }
  
  render() {
    const {data} = this.props
    const posts = data.allAirtable.edges
    // console.log(posts)
    let valoriUniciCitta = [...new Set(posts.map(({node}) => {return node.data.Citta} ))]
    let nomiCitta = []
    valoriUniciCitta.forEach(citta => {
        citta.forEach(x => { 
            const nomeslug = slugify(x)
            nomiCitta.push({
                nome: x,
                slug: `/ramadan-2019/${nomeslug}` 
            })
        })
    })
    nomiCitta.sort(function(a, b) {
      return (a.nome < b.nome) ? -1 : (a.nome > b.nome) ? 1 : 0;
    })
    // console.log(nomiCitta)

    return (
      <Layout>   
        <aside className={style.aside}>
            {/* <form>
                <input type="text" placeholder="Cerca 🔍" />
            </form> */}
            <nav>
              <p onClick={(e) => this.handleClick("all")} className={this.state.filter === 'all' ? style.activeItem : ''}><span className={style.firstActiveItem}></span>Tutte le tappe</p>
              {nomiCitta.map(citta => (
                  <p onClick={(e) => this.handleClick(citta.nome)} className={this.state.filter === citta.nome ? style.activeItem + " " + style.firstActiveItem : ''} key={citta.slug}>{citta.nome}</p>
              ))}
            </nav>
        </aside>

        <main>
          <section className={style.hero + " tre-colonne"}>
            <img alt="" src={logo}></img>
            <Link to="/ramadan-2019/messaggio" className={style.intro}>Alla sua III edizione, l'Iniziativa si accompagna quest’anno all’ottocentesimo anniversario dell’incontro tra San Francesco e il Sultano Muhammad Malik Al-Kamil e nelle immediate vicinanze dei due viaggi compiuti da Sua Santità Papa Francesco in Marocco e ad Abu Dhabi &#10511;</Link>
            {/* DONATE */}
            <div className={style.contribuisci}>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="R5FJLTQ9YAPXY" />
                {/* <input type="image" src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Fai una donazione con il pulsante PayPal" /> */}
                {/* <img alt="" border="0" src="https://www.paypal.com/it_IT/i/scr/pixel.gif" width="1" height="1" /> */}
                <button className={style.payPal}>
                  <span className="size-3">🤲</span>
                  <span>PARTECIPA CON UNA DONAZIONE</span>
                </button>
              </form>
            </div>
          </section>

          
          <section>
            <h4 className="bordo">Con il contributo di:</h4>
            <div className={style.sponsor}>
              <img height="35" src={coopfond} alt=""/>
              <img height="40" src={sanpaolo} alt=""/>
              <img height="80" src={cariplo} alt=""/>
              <img height="60" src={iom} alt=""/>
              <img height="50" src={aice} alt=""/>
              <img height="25" src={halalitalia} alt=""/>
            </div>
          </section>
          <section className={style.ambasciate}>
            <div>
              <img height="25" src={saudiarabia} alt=""/>
              <p>Ambasciata<br/> Arabia Saudita</p>
            </div>
            <div>
              <img height="25" src={iu} alt=""/>
              <p>Ambasciata<br/> Indonesia</p>
            </div>
            <div>
              <img height="25" src={my} alt=""/>
              <p>Ambasciata<br/> Malesya</p>
            </div>
            <div>
              <img height="25" src={kuwait} alt=""/>
              <p>Ambasciata<br/> Kuwait</p>
            </div>
          </section>


          <section className="tre-colonne">
            { posts.map(({node}) => (
              <Card year="ramadan-2019" filter={this.state.filter} data={node} key={node.id}></Card>
            )) }
          </section>
        </main>
      </Layout>
    )
  }
}


export const query = graphql`
  query {
    allAirtable( filter: {table: {eq: "Iftar2019"}} sort: {fields: [data___Data] order: ASC} ) {
      edges {
        node {
          id
          data {
            Slug
            Pasti
            Data(formatString: "DD MMMM YYYY" locale: "it-IT")
            Luogo
            Citta
            Immagini {
              url
            }
            Partner {
              data {
                Nome
              }
            }
          }
        }
      }
    }
  }
`
