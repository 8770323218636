// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contatti-jsx": () => import("/opt/build/repo/src/pages/contatti.jsx" /* webpackChunkName: "component---src-pages-contatti-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-ramadan-2017-index-jsx": () => import("/opt/build/repo/src/pages/ramadan-2017/index.jsx" /* webpackChunkName: "component---src-pages-ramadan-2017-index-jsx" */),
  "component---src-pages-ramadan-2017-messaggio-jsx": () => import("/opt/build/repo/src/pages/ramadan-2017/messaggio.jsx" /* webpackChunkName: "component---src-pages-ramadan-2017-messaggio-jsx" */),
  "component---src-pages-ramadan-2018-index-jsx": () => import("/opt/build/repo/src/pages/ramadan-2018/index.jsx" /* webpackChunkName: "component---src-pages-ramadan-2018-index-jsx" */),
  "component---src-pages-ramadan-2018-messaggio-jsx": () => import("/opt/build/repo/src/pages/ramadan-2018/messaggio.jsx" /* webpackChunkName: "component---src-pages-ramadan-2018-messaggio-jsx" */),
  "component---src-pages-ramadan-2019-messaggio-jsx": () => import("/opt/build/repo/src/pages/ramadan-2019/messaggio.jsx" /* webpackChunkName: "component---src-pages-ramadan-2019-messaggio-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

