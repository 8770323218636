import React from "react"
import style from "./basic-layout.module.css"
import Navbar from "../navbar/navbar"


export default ({ children }) => {
  return (
    <div className={style.wrapper}>
      <Navbar></Navbar>
      {children}
    </div>
  )
}