import React from 'react'
import Layout from '../components/post-layout/post-layout'
import { graphql, Link } from 'gatsby'
import style from './post.module.css'

export default ({data}) => {
    const post = data.airtable.data
    console.log(post)
    const year = post.Data.slice(-4)
    const currentYear = new Date().getFullYear()
    const backUrl =  year === currentYear+"" ? '' : `ramadan-${year}`

    console.log("year", year)
    console.log("currentYear", currentYear)
    console.log("backUrl", backUrl)
    
    return (
        <Layout>
            <aside className={style.aside}>
                <div>
                    <Link to={`/${backUrl}`} className={style.backLink}><b>&lsaquo; Torna a Eventi Ramadan {year}</b></Link>
                    <h1 className={'rosso'}>{post.Luogo}</h1>
                    <h4>{post.Data}</h4>
                    
                    <ul className={style.lista}>
                        <li><a href={`https://www.google.com/maps/search/?api=1&query=${post.Indirizzo} ${post.Citta} ${post.Regione}`} target="_blank" rel="noopener noreferrer">{`${post.Indirizzo}, ${post.Citta}, ${post.Regione}`}</a></li>
                        {/* LOCANDINA */}
                        {post.Locandine
                            ? 
                            <React.Fragment>
                                <li><a href={post.Locandine[0].url} target="_blank" rel="noopener noreferrer">Locandina</a></li>
                            </React.Fragment>
                            :
                            null
                        }
                    </ul>

                    {/* PARTNER */}
                    {post.Partner
                        ? 
                        <React.Fragment>
                            <h4>Partner</h4>
                            <ul className={style.lista}>
                                {post.Partner.map((partner) => {
                                    const queryname = partner.data.Nome.replace(" ", "+")
                                    const url = partner.data.Sito ? partner.data.Sito : `https://www.google.com/search?q=${queryname}`
                                    return(<li key={partner.data.Nome}><a href={url} target="_blank" rel="noopener noreferrer">{partner.data.Nome}</a></li>)
                                })}
                            </ul>
                        </React.Fragment>
                        :
                        null
                    }

                    {/* RASSEGNA STAMPA */}
                    {post.Rassegna
                        ? 
                        <React.Fragment>
                            <h4>Rassegna Stampa</h4>
                            <ul className={style.lista}>
                                {post.Rassegna.map(({data}) => {
                                    return(<li key={data.Link}><a href={data.Link} target="_blank" rel="noopener noreferrer">{data.Nome_sito}</a></li>)
                                })}
                            </ul>
                        </React.Fragment>
                        :
                        null
                    }
                    
                    {/* PASTI */}
                    {post.Pasti
                        ? 
                        <React.Fragment>
                            <h4 className={style.pasti}>Pasti offerti</h4>
                            <p className={style.numeroPasti}>{post.Pasti}</p> 
                        </React.Fragment>
                        :
                        null
                    }
            
                </div>
            </aside>

            <main className={style.materiali}>
                {/* IMMAGINI */}
                {post.Immagini
                    ? 
                    <React.Fragment>
                        {post.Immagini.map((immagine) => {
                            return(<img key={immagine.url} src={immagine.url} alt=""/>)
                        })}
                    </React.Fragment>
                    :
                    null
                }
            </main>

        </Layout>
    )
}

export const query = graphql`
    query($slug: String!, $anno: String!) {
        airtable(table: {eq: $anno}, data: {Slug: {eq: $slug}}) {
            data {
                Pasti
                Data(formatString: "DD MMMM YYYY" locale: "it-IT")
                Luogo
                Indirizzo
                Citta
                Regione
                Locandine {
                    url
                }
                Presenza_Istituzioni
                Immagini {
                    url
                }
                Partner {
                    data {
                        Sito
                        Nome
                    }
                },
                Rassegna {
                    data {
                        Nome_sito
                        Link
                    }
                }
            } 
        }
    }
`
