import React from 'react'
import Layout from "../components/full-layout/full-layout"
import style from "./contatti.module.css"
import logo from "../img/logo.png"


export default () => {
    return (
        <Layout>
            <main className={style.main}>
                <img width="140" src={logo} alt="114 Pizza e Dolci Ramadan COREIS"/>
                <h1 className="bordo">CONTATTI</h1>
                <p className="size-4 bordo">Il progetto 114 Pizza e Dolci è coordinato dalla <br/> <a className="rosso" target="_blank"  href="http://www.coreis.it">
                    COREIS (Comunità Religiosa Islamica) Italiana</a>
                </p>
                <ul>
                    <li>Scrivici a <a href="mailto:integrazione@coreis.it">integrazione@coreis.it</a></li>
                    <li>Seguici su <a target="_blank" href="https://www.facebook.com/coreis.giovani/">Facebook</a></li>
                </ul>
            </main>
        </Layout>
    )
}