import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/full-layout/full-layout"
import style from "./404.module.css"
import logo from "../img/logo.png"


export default () => {
    return (
        <Layout>
            <main className={style.main}>
                <img width="140" src={logo} alt="114 Pizza e Dolci Ramadan COREIS"/>
                <h1 className="rosso size-1">404</h1>
                <p className="size-4 bordo">Quello che cerchi non si trova qui...</p> 
                <p><Link className="rosso"  to="/">Torna alla 🏠 🍕</Link></p>
            </main>
        </Layout>
    )
}