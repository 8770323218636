import React from "react"
import style from "./navbar.module.css"
import {Link} from "gatsby"
import Logo from "../../img/icona.png"

export default class Navbar extends React.Component {
    state = {
        open: false
    }
    
    handleClick = () => {
        this.setState({
            open: !this.state.open
        })
    }
    render () {
        return (
            <div className={this.state.open ? style.navbar + " " + style.open : style.navbar + " " + style.close}>
                <nav>
                    <Link activeClassName={style.active} className={style.navbarItem} to="/">
                        <img width="50" alt="Logo icona 114 Pizza e dolci" src={Logo} />
                    </Link>
                    <Link activeClassName={style.active} className={style.navbarItem} to="/">
                        <p>2019</p>
                    </Link>
                    <Link activeClassName={style.active} className={style.navbarItem} to="/ramadan-2018">
                        <p>2018</p>
                    </Link>
                    <Link activeClassName={style.active} className={style.navbarItem} to="/ramadan-2017">
                        <p>2017</p>
                    </Link>
                    {/* <Link activeClassName={style.active} className={style.navbarItem + " hideMobile"} to="/partner">
                        <p>PARTNER</p>
                    </Link> */}
                    <Link activeClassName={style.active} className={style.navbarItem + " hideMobile"} to="/contatti">
                        <p>CONTATTI</p>
                    </Link>
        
                    <div onClick={this.handleClick} className={style.hamburger}>
                        {
                        this.state.open 
                        ?
                        <span className={style.big}>&times;</span>
                        :
                        <span>&#9776;</span>
                        }
                    </div>
                </nav>

                <div className={style.secondaryMenu}>
                    {/* <Link activeClassName={style.active} className={style.navbarSecondaryItem} to="/partner">PARTNER</Link> */}
                    <Link activeClassName={style.active} className={style.navbarSecondaryItem} to="/contatti">CONTATTI</Link>
                </div>
            </div>
        )
    }
} 
