import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../../components/basic-layout/basic-layout"
import style from "../index.module.css"
import logo from "../../img/logo.png"
import Card from "../../components/card/card"
import slugify from "../../utils/slugify"

export default class IndexPage extends React.Component {
  
  state = {
    filter: "all"
  }

  handleClick = (citta) => {
    this.setState({
      filter: citta
    })
  }
  
  render() {
    const {data} = this.props
    const posts = data.allAirtable.edges
    console.log(posts)
    let valoriUniciCitta = [...new Set(posts.map(({node}) => {return node.data.Citta} ))]
    let nomiCitta = []
    valoriUniciCitta.forEach(citta => {
        citta.forEach(x => { 
            const nomeslug = slugify(x)
            nomiCitta.push({
                nome: x,
                slug: `/ramadan-2018/${nomeslug}` 
            })
        })
    })
    nomiCitta.sort(function(a, b) {
      return (a.nome < b.nome) ? -1 : (a.nome > b.nome) ? 1 : 0;
    })
    // console.log(nomiCitta)

    return (
      <Layout>   
        <aside className={style.aside}>
            {/* <form>
                <input type="text" placeholder="Cerca 🔍" />
            </form> */}
            <nav>
              <p onClick={(e) => this.handleClick("all")} className={this.state.filter === 'all' ? style.activeItem : ''}><span className={style.firstActiveItem}></span>Tutte le tappe</p>
              {nomiCitta.map(citta => (
                  <p onClick={(e) => this.handleClick(citta.nome)} className={this.state.filter === citta.nome ? style.activeItem + " " + style.firstActiveItem : ''} key={citta.slug}>{citta.nome}</p>
              ))}
            </nav>
        </aside>

        <main>
        <section className={style.hero + " tre-colonne"}>
            <img alt="" src={logo}></img>
            <Link className={style.intro} to="/ramadan-2018/messaggio">Il titolo e il numero 114 Pizza e Dolci fa riferimento ai 114 capitoli del Sacro Corano. Ogni capitolo riunisce una molteplicità dei versetti, che secondo l'insegnamento di alcuni maestri, corrispondono alla varietà dei segni di Dio... &#10511;</Link>
            {/* DONATE */}
            <div className={style.contribuisci}>
              <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="R5FJLTQ9YAPXY" />
                {/* <input type="image" src="https://www.paypalobjects.com/it_IT/IT/i/btn/btn_donateCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Fai una donazione con il pulsante PayPal" /> */}
                {/* <img alt="" border="0" src="https://www.paypal.com/it_IT/i/scr/pixel.gif" width="1" height="1" /> */}
                <button className={style.payPal}>
                  <span className="size-3">🤲</span>
                  <span>PARTECIPA CON UNA DONAZIONE</span>
                </button>
              </form>
            </div>
          </section>

          <section className="tre-colonne">
            { posts.map(({node}) => (
              <Card year={"ramadan-2018"} filter={this.state.filter} data={node} key={node.id}></Card>
            )) }
          </section>
        </main>
      </Layout>
    )
  }
}


export const query = graphql`
  query {
    allAirtable( filter: {table: {eq: "Iftar2018"}} sort: {fields: [data___Data] order: ASC} ) {
      edges {
        node {
          id
          data {
            Slug
            Pasti
            Data(formatString: "DD MMMM YYYY" locale: "it-IT")
            Luogo
            Citta
            Immagini {
              url
            }
            Partner {
              data {
                Nome
              }
            }
          }
        }
      }
    }
  }
`
