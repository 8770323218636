import React from "react"
import {Link} from "gatsby"
import style from "./card.module.css"
import slugify from "../../utils/slugify"

export default ({data, filter, year}) => {
    if(filter == "all" || filter == data.data.Citta) {
        return (
            <Link className={style.articleItem} to={`/${year}/${slugify(data.data.Citta)}/${data.data.Slug}`} key={data.id}>
                {data.data.Immagini  
                    ? 
                    <React.Fragment>
                        <img className={style.articleImg} alt={data.data.Luogo} src={data.data.Immagini[0].url}></img>
                        <div className={style.articleBgImage} style={{backgroundImage: 'url('+data.data.Immagini[0].url+')'}}></div>
                    </React.Fragment>
                    :
                    null
                }
                <header>
                    <h5 className={style.articleUpperText}>{data.data.Citta}, {data.data.Data}</h5>
                    <h3 className={style.articleTitle}>{data.data.Luogo}</h3>
                    {data.data.Partner
                        ? 
                        <React.Fragment>
                            <p className={style.articleText}>
                                {data.data.Partner.map((p, index) => {
                                    if(index < data.data.Partner.length - 1) {
                                        return (<span key={index}>{p.data.Nome}<span>, </span></span>)
                                    } else {
                                        return (<span key={index}>{p.data.Nome}</span>)
                                    }
                                })}
                            </p>
                        </React.Fragment>
                        :
                        null
                    }
                </header>
            </Link>
        )
    } else {
        return(
            null
        )
    }
}

