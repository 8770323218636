import React from 'react'
import {Link} from 'gatsby'
import Layout from "../../components/full-layout/full-layout"
import style from "../messaggio.module.css"
import pallavicini from "../../img/Pallavicini.jpg"


export default () => {
    return (
        <Layout>
            <main className={style.main}>
                <header>
                    <p> <Link to="/ramadan-2018"><b>&lsaquo; Torna agli Eventi del 2018</b></Link> </p>
                    <h1 className="bordo">MESSAGGIO PER IL <span className="rosso">RAMADAN 2018</span></h1>
                </header>
                <p className="size-3">Il titolo e il numero 114 Pizza e Dolci fa riferimento ai 114 capitoli del Sacro Corano. Ogni capitolo riunisce una molteplicità dei versetti, che secondo l'insegnamento di alcuni maestri, corrispondono alla varietà dei segni di Dio.</p>
                <p>Benvenuti alla seconda edizione del nostro programma 114 Pizza & Dolci! Da alcuni anni la COREIS ITALIANA ha avviato un percorso di assistenza e sostegno agli immigrati per favorire non solo l'insegnamento volontario della lingua italiana, l'accompagnamento alla cittadinanza italiana e allo studio e al lavoro in Italia, ma anche la sensibilizzazione al patrimonio spirituale e culturale dell'Islam nel dialogo interreligioso e interculturale.</p>
                <p>La COREIS ITALIANA con questo progetto vuole cercare di interagire, accompagnare e onorare ogni persona, migrante, povero o rifugiato come un segno di Dio. Quest'anno il nuovo titolo è 114 Pizza & Dolci. 2018 Quaresima e Ramadan 1439. </p>
                <p>L'obiettivo è quello di organizzare due cicli di cene da offrire sia durante la Quaresima (Marzo) che durante il Ramadan (Giugno) per i poveri, i migranti e i rifugiati in Italia. Infatti attraverso il simbolismo del digiuno e del nutrimento ci auguriamo si possa ottenere un momento di dialogo interreligioso e integrazione tra cristiani e mussulmani di provenienza estera che desiderano integrarsi.</p>
                <p>Papa Francesco nel suo recente messaggio in occasione della Giornata Mondiale del Migrantee del Rifugiato ha invitato i credenti ad unirsi sulla base di 4 parole chiave: accogliere, proteggere, promuovere ed integrare.</p>
                <p>Come musulmani italiani vogliamo rispondere a questo invito di responsabilità nei confronti di uomini e donne che arrivano e vivono in Italia con il bisogno di trovare un conforto spirituale, familiare, giuridico, culturale e sociale.</p>
                <p>Anche quest'anno le cene verranno organizzate in collaborazione con associazioni cristiane e laiche locali che si occupano da anni di assistenza e sostegno agli immigrati di diverse confessioni provenienti dal Mediterraneo, Dal Medio Oriente, Dall'Africa e dall'Asia.</p>
                <img className={style.profilePic} src={pallavicini} alt="Imam Yahya Pallavicini"/>
                <p className="bordo"><b>Imam Yahya Pallavicini</b> <br/> Presidente COREIS (Comunità Religiosa Islamica) Italiana</p>
            </main>
        </Layout>
    )
}