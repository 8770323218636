import React from "react"
import style from "./full-layout.module.css"
import Navbar from "../navbar/navbar"


export default ({ children }) => {
  return (
    <div className={style.wrapper}>
      <Navbar></Navbar>
      <main className={style.main}>
        {children}
      </main>
    </div>
  )
}